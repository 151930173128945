/* eslint-disable react/jsx-no-comment-textnodes */
import * as React from 'react'
import { graphql } from 'gatsby'
import Layout from 'components/Layout'
import BlogCategories from 'components/blog/BlogCategories'
import Seo from '../../components/Seo'
import { BlogPostQuery } from 'types'

type BlogPageProps = {
  data: {
    allMdx: {
      nodes: BlogPostQuery[]
    }
  }
}

const BlogPage = ({ data }: BlogPageProps) => {
  const blogPosts = data.allMdx.nodes.filter(
    (node) => node.fields.collection === 'blog'
  )

  // generating list of unique categories
  let categories: string[][] | string[] = blogPosts.map(
    (post) => post.frontmatter.categories
  )
  categories = ([] as string[]).concat
    .apply([], categories)
    .filter((item, index, array) => array.indexOf(item) === index)
    .sort()

  return (
    <Layout>
      {/* HERO */}
      <div className="py-32 bg-blog-tbd">
        <div className="inner-container">
          <p className="text-white subtitle opacity-70">// Blog</p>
          <div className="text-white lg:w-5/12">
            <h1>Read about the world of Xanadu</h1>
          </div>
        </div>
      </div>
      {/* ALL POSTS */}
      <BlogCategories categories={categories} posts={blogPosts} />
    </Layout>
  )
}

export const query = graphql`
  query {
    allMdx(sort: { fields: frontmatter___date, order: DESC }) {
      nodes {
        frontmatter {
          date(formatString: "MMMM D, YYYY")
          title
          slug
          banner {
            childrenImageSharp {
              gatsbyImageData(transformOptions: { fit: COVER })
            }
          }
          categories
        }
        fields {
          collection
        }
        id
        slug
        excerpt
      }
    }
  }
`

export default BlogPage

export const Head = () => {
  return (
    <Seo
      title="Blog"
      description="Read about Xanadu's latest milestones"
      image="blog_hero_bg.jpg"
    />
  )
}
