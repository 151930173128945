import * as React from 'react'

type DifficultyBarProps = {
  difficulty: Difficulty
  text?: boolean
}

const DifficultyBar = ({ difficulty, text }: DifficultyBarProps) => {
  let width = ''
  let difficultyText = ''
  if (difficulty === 'easy') {
    width = 'w-1/3'
    difficultyText = 'Easy'
  } else if (difficulty === 'medium') {
    width = 'w-2/3'
    difficultyText = 'Medium'
  } else if (difficulty === 'hard') {
    width = 'w-full'
    difficultyText = 'Hard'
  }

  const bar = (
    <span className="h-0.75 w-25 self-center relative inline-block">
      <span className="absolute inline-block w-full h-full bg-grey-800 opacity-10"></span>
      <span
        className={`absolute inline-block ${width} h-full bg-grey-800 difficulty-bar__level`}
      ></span>
    </span>
  )

  return text ? (
    <div className="inline-flex items-center">
      <p className="flex-none text-xs font-semibold uppercase mr-2.5 difficulty-bar__text">
        {difficultyText}
      </p>
      {bar}
    </div>
  ) : (
    bar
  )
}

export default DifficultyBar
