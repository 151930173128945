import * as React from 'react'
import { useState, useEffect } from 'react'
import { titleCase } from 'title-case'
import scrollTo from 'gatsby-plugin-smoothscroll'

import postTidier from 'utils/postTidier'

import BlogBoxes from 'components/blog/BlogBoxes'

type BlogCategoriesProps = {
  categories: string[]
  posts: BlogPostQuery[]
}

const BlogCategories = ({ categories, posts }: BlogCategoriesProps) => {
  // removing bulk from incoming posts array
  const reducedPosts = postTidier(posts)

  // STATE
  const [filteredPosts, setFilteredPosts] = useState(reducedPosts)

  const [currentCategory, setCurrentCategory] = useState<string | undefined>(
    'all'
  )

  const blogPostIteration = 4 // number of blog posts to show each button press
  const [lengthBlogPosts, setLengthBlogPosts] = useState(reducedPosts.length)
  const [blogPostsVisible, setBlogPostsVisible] = useState(blogPostIteration)

  const [activeCategory, setActiveCategory] = useState(0)

  // SORTING LOGIC
  const changeCategory = (dataset: DOMStringMap) => {
    setCurrentCategory(dataset.option)
    setActiveCategory(parseInt(dataset.activeIndex as string))
  }

  // when category changes, filter posts again
  useEffect(() => {
    setFilteredPosts(
      currentCategory === 'all'
        ? reducedPosts
        : reducedPosts.filter((post) => {
            return post.categories.some(
              (category) => category === currentCategory
            )
          })
    )
  }, [currentCategory])

  // SHOW MORE LOGIC
  // when filtered posts array changes, recalculate length of array, reset blog posts visible
  useEffect(() => {
    setLengthBlogPosts(filteredPosts.length)
    setBlogPostsVisible(blogPostIteration)
  }, [filteredPosts])

  const showMore = () => {
    setBlogPostsVisible(blogPostsVisible + blogPostIteration)
  }

  const renderShowMoreButton = () => {
    if (blogPostsVisible >= lengthBlogPosts) return null
    return (
      <button className="btn btn-lg btn-1" onClick={showMore}>
        Load more
      </button>
    )
  }

  const selectedStyles = 'font-semibold border-b'

  return (
    <div className="flex flex-row flex-wrap standard-page-section inner-container">
      {/* NAV */}
      <div className="w-full lg:w-1/4">
        <div className="mb-10 lg:mb-0 lg:sticky lg:top-24">
          <h3 className="block mb-5 lg:mb-10 lg:hidden">Blog Articles</h3>
          <h6 className="jumptarget" id="blog-categories">
            Categories
          </h6>
          <div
            data-option="all"
            data-active-index={0}
            onClick={(e) => changeCategory(e.currentTarget.dataset)}
            className="mt-5 lg:mt-7"
          >
            <button className={`${activeCategory == 0 ? selectedStyles : ''}`}>
              All
            </button>
          </div>
          {categories.map((category, index) => {
            return (
              <div
                data-option={category}
                data-active-index={index + 1}
                className="mt-1.5"
                onClick={(e) => {
                  changeCategory(e.currentTarget.dataset)
                  scrollTo('#blog-articles')
                  scrollTo('#blog-categories')
                }}
                key={index}
              >
                <button
                  className={`${
                    activeCategory == index + 1 ? selectedStyles : ''
                  }`}
                >
                  {titleCase(category)}
                </button>
              </div>
            )
          })}
        </div>
      </div>
      {/* MAIN */}
      <div
        className="w-full overflow-hidden jumptarget lg:w-3/4"
        id="blog-articles"
      >
        <h3 className="hidden mb-10 lg:block">Blog Articles</h3>
        <BlogBoxes
          width="w-1/2"
          flexBreakpointMedium={true}
          blogPosts={filteredPosts.slice(0, blogPostsVisible)}
          margins={{ title: 'mt-2.5', excerpt: 'mt-5', type: 'mt-7.5' }}
          imageHeight="h-62.5"
          spacing={{ margin: '-mx-3 -my-7.5', padding: 'px-3 py-7.5' }}
        />
        {/* {renderShowLessButton()} */}
        <div className="mt-20 text-center">{renderShowMoreButton()}</div>
      </div>
    </div>
  )
}

export default BlogCategories
