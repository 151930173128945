/* eslint-disable react/jsx-no-comment-textnodes */
import * as React from 'react'
import { Link } from 'gatsby'
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image'
import { titleCase } from 'title-case'
import { skipNoReferrer } from '../../utils/url_helpers'
import DifficultyBar from 'components/DifficultyBar'
import { BlogPreview } from 'types'

type GatsbyLinkShellProps = {
  content: JSX.Element
  link: string
  props?: Record<string, unknown>
}
const GatsbyLinkShell = ({
  content,
  link,
  props = {},
}: GatsbyLinkShellProps) => {
  return (
    <Link to={link} {...props}>
      {content}
    </Link>
  )
}
type AnchorShellProps = {
  content: JSX.Element
  link: string
  props?: Record<string, unknown>
}
const AnchorShell = ({ content, link, props = {} }: AnchorShellProps) => {
  return (
    // eslint-disable-next-line react/jsx-no-target-blank
    <a
      href={link}
      target="_blank"
      rel={`noopener ${skipNoReferrer(link) ? '' : 'noreferrer'}`}
      {...props}
    >
      {content}
    </a>
  )
}

export type BlogBoxProps = {
  blogPosts: BlogPreview
  margins?: {
    type?: `mt-${number}`
    title?: `mt-${number}`
    excerpt?: `mt-${number}`
  }
  spacing?: {
    margin: string
    padding: string
  }
  imageHeight?: `h-${number}`
  noImage?: boolean
  categoryOverride?: {
    text: string
    link?: string
  }
  linkExternal?: boolean
  excerptVisible?: boolean
  tagAsCollection?: boolean
}
const BlogBox = ({
  blogPosts,
  margins,
  imageHeight,
  noImage,
  categoryOverride,
  spacing,
  linkExternal,
  excerptVisible,
  tagAsCollection,
}: BlogBoxProps) => {
  const { image, type, categories, title, difficulty, excerpt, link } =
    blogPosts

  const LinkShell = linkExternal ? AnchorShell : GatsbyLinkShell

  return (
    <div className={`${spacing?.padding || 'py-6.25 lg:p-6.25'}`}>
      <LinkShell
        link={link}
        content={
          <>
            {!noImage ? (
              image ? (
                <GatsbyImage
                  className={`w-full ${imageHeight || 'h-50'}`}
                  image={image}
                  alt={title}
                />
              ) : (
                <StaticImage
                  src={'../../images/blog_image_placeholder.png'}
                  alt={title}
                  className={`w-full ${imageHeight || 'h-50'}`}
                />
              )
            ) : (
              ''
            )}
          </>
        }
      />
      {!categoryOverride && (categories || tagAsCollection) && (
        <div className={margins?.type || 'mt-7.5'}>
          <div className="block">
            <div className="tag">
              //{' '}
              {
                // if catgory present check for option to show collection rather than category
                // if category not present, show collection
                categories
                  ? tagAsCollection
                    ? titleCase(type)
                    : titleCase(categories[0])
                  : titleCase(type)
              }
            </div>
          </div>
        </div>
      )}
      {categoryOverride && (
        <div className={margins?.type || 'mt-7.5'}>
          {categoryOverride.link ? (
            <Link to={categoryOverride.link} className="block">
              <div className="tag hover:bg-primary-500 hover:border-primary-500 hover:opacity-90">
                {categoryOverride.text}
              </div>
            </Link>
          ) : (
            <div className="block">
              <div className="tag">{categoryOverride.text}</div>
            </div>
          )}
        </div>
      )}
      <h5 className={margins?.title || 'mt-2.5'}>
        <LinkShell
          link={link}
          props={{ className: 'inline-block' }}
          content={<>{title}</>}
        />
      </h5>
      {excerpt && excerptVisible && (
        <p className={margins?.excerpt || 'mt-3.75'}>{excerpt}</p>
      )}
      {difficulty && (
        <div className="block mt-3">
          <DifficultyBar difficulty={difficulty} text={true} />
        </div>
      )}
    </div>
  )
}

export default BlogBox
