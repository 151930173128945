import * as React from 'react'

import { BlogBoxProps } from 'components/blog/BlogBox'

import BlogBox from 'components/blog/BlogBox'

type BlogBoxesProps = {
  blogPosts: BlogPreview[]
  width?: FlexWidth
  // set breakpoint for mobile to medium rather than large
  flexBreakpointMedium?: boolean
  marginTop?: `mt-${number}`
  imageHeight?: BlogBoxProps['imageHeight']
  margins?: BlogBoxProps['margins']
  spacing?: BlogBoxProps['spacing']
  excerptVisible?: BlogBoxProps['excerptVisible']
  tagAsCollection?: BlogBoxProps['tagAsCollection']
}

const BlogBoxes = ({
  width,
  flexBreakpointMedium,
  blogPosts,
  margins,
  imageHeight,
  spacing,
  excerptVisible,
  tagAsCollection,
}: BlogBoxesProps) => {
  return (
    <div
      className={`flex flex-row flex-wrap ${
        spacing?.margin || '-my-6.25 lg:-m-6.25'
      }`}
    >
      {blogPosts.map((item, index) => {
        return (
          <div
            className={`w-full ${flexBreakpointMedium ? 'md:' : 'lg:'}${width}`}
            key={index}
          >
            <BlogBox
              blogPosts={item}
              margins={margins}
              imageHeight={imageHeight}
              spacing={spacing}
              excerptVisible={
                excerptVisible === undefined ? true : excerptVisible
              }
              tagAsCollection={tagAsCollection}
            />
          </div>
        )
      })}
    </div>
  )
}

export default BlogBoxes
