const postTidier = (posts: BlogPostQuery[]) => {
  return posts.map((post) => {
    const item: BlogPreview = {
      image: post.frontmatter.banner?.childrenImageSharp[0].gatsbyImageData,
      type: post.fields.collection,
      title: post.frontmatter.title,
      excerpt: post.excerpt,
      categories: post.frontmatter.categories,
      link: `/blog/${post.frontmatter.slug}`,
    }

    if (post.frontmatter.difficulty) {
      item.difficulty = post.frontmatter.difficulty
    }

    return item
  })
}

export default postTidier
